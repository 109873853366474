import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../css/navbar/navbar.css';
import profileImage from '../../assets/avatar.png';
import menuImage from '../../assets/menu.png';
import { FaUser, FaCog, FaSignOutAlt } from 'react-icons/fa';

function Navbar() {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const usernameID = localStorage.getItem('username');
    setUsername(usernameID);
  }, []);

  const toggleProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu);
  };

  const handleLogout = () => {
    const confirmLogout = window.confirm('Are you sure you want to log out?');
    if (confirmLogout) {
      localStorage.clear();
      alert('Logged out!');
      navigate('/login');
    } else {
      alert('Logout canceled.');
    }
  };

  const handleProfileClick = () => {
    navigate('/profile');
  };

  const handleSettingsClick = () => {
    navigate('/settings');
  };

  return (
    <div className="navbar-card1">
      <div className="notification-profile">
        <img src={menuImage} alt="Menu" className="menu-icon" />
        <i className="fas fa-bell notification-icon"></i>
        <div className="profile-avatar" onClick={toggleProfileMenu}>
          <img src={profileImage} alt="Profile" className="avatar" />
        </div>
        {showProfileMenu && (
          <div className="profile-menu">
            <div className="profile-menu-header">
              <img src={profileImage} alt="Profile" className="profile-menu-avatar" />
              <div>
                <span className="profile-menu-name">{username}</span>
                <span className="profile-menu-role">Employee</span>
              </div>
            </div>
            <div className="profile-menu-item" onClick={handleProfileClick}>
              <FaUser className="profile-menu-icon" />
              <span>My Profile</span>
            </div>
            <div className="profile-menu-item" onClick={handleSettingsClick}>
              <FaCog className="profile-menu-icon" />
              <span>Settings</span>
            </div>
            <div className="profile-menu-item profile-menu-logout" onClick={handleLogout}>
              <FaSignOutAlt className="profile-menu-icon" />
              <span>Logout</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;